<template>
	<span>
		<v-btn @click="activate = true" icon>
			<v-icon> mdi-account </v-icon>
		</v-btn>

		<UserProfileUpdate
			:activateDialog="activate"
			@close:profile="activate = false"
		/>
	</span>
</template>

<script>
	import UserProfileUpdate from '@/components/UserProfileUpdate';

	export default {
		name: 'top-bar-user',

		data() {
			return {
				activate: false,
			};
		},

		components: {
			UserProfileUpdate,
		},
	};
</script>
