<template>
	<div>
		<v-avatar color="primary" size="128">
			<v-img v-if="imageBase64" :src="imageBase64">
				<v-icon dark> mdi-camera-outline </v-icon>
			</v-img>
			<v-icon v-else dark> mdi-camera-outline </v-icon>
		</v-avatar>

		<!-- Input file donde se adjunta la imagen -->
		<v-file-input
			v-model="fileToUpload"
			accept="image/png, image/jpeg, image/bmp"
			placeholder="Escoga una foto para su perfil"
			label="Foto perfil de usuario"
			@change="openEdit"
		></v-file-input>

		<h1>2 Recorta</h1>
		<!-- Editor donde se recortará la imagen con la ayuda de croppr.js -->
		<div id="editor" style="width: 300px; height: 300px"></div>
	</div>
</template>

<script>
	import Croppr from 'croppr';

	import { mapState } from 'vuex';

	export default {
		name: 'user-profile-picture',

		data() {
			return {
				fileToUpload: null,

				imageBase64: null, // Imagen en base64
				setSize: 200, // Estableciendo el tamaño de la imagen a generar

				editLayer: null, // Donde se edita la imagen seleccionada
				croppr: null, // Instancia de la libreria Croppr
			};
		},

		watch: {
			fileToUpload(value) {
				if (!value) this.editLayer.innerHTML = '';
			},

			imageBase64(newPicture) {
				this.$emit('updatedPicture', newPicture);
			},
		},

		computed: {
			...mapState({
				user: state => state.userControl.user,
			}),

			urlImage() {
				return this.fileToUpload
					? URL.createObjectURL(this.fileToUpload)
					: {};
			},
		},

		mounted() {
			this.loadHead();
			this.editLayer = document.querySelector('#editor');

			this.imageBase64 = this.user.picture;
		},

		methods: {
			isCorrectDimensions() {
				// Dimensiones de la imagen para validar
				return new Promise(resolve => {
					const img = new Image();
					img.onload = function () {
						resolve({
							width: this.width.toFixed(0),
							height: this.height.toFixed(0),
						});
					};

					img.src = this.urlImage;
				});
			},

			loadHead() {
				// Verificar si existe el css antes de agregarlo
				const existingCss = document.getElementById('cropprCss');

				if (!existingCss) {
					/* Agregando CDN css para recortar las imagenes */
					const css = document.createElement('link');
					css.href =
						'https://cdn.jsdelivr.net/gh/jamesssooi/Croppr.js@2.3.0/dist/croppr.min.css';
					css.rel = 'stylesheet';
					css.id = 'cropprCss';
					document.head.appendChild(css);
				}
			},

			async openEdit() {
				const imgDimensions = await this.isCorrectDimensions(),
					size = this.setSize * 2;

				if (
					imgDimensions.width >= size &&
					imgDimensions.height >= size
				) {
					// Borra editor en caso que existiera una imagen previa
					this.editLayer.innerHTML = '';

					const cropprImg = document.createElement('img');
					cropprImg.setAttribute('id', 'croppr');
					this.editLayer.appendChild(cropprImg);

					// Envia la imagen al editor para su recorte
					document
						.querySelector('#croppr')
						.setAttribute('src', this.urlImage);

					// Crea el editor
					this.croppr = new Croppr('#croppr', {
						aspectRatio: 1,
						minSize: [this.setSize / 2, this.setSize / 2, 'px'],
						onCropEnd: this.cutImage,
					});
				} else {
					alert(
						`La imagen seleccionada es muy pequeña. Debe ser de al menos ${size}px de alto y de ancho`
					);
					console.log('La imagen seleccionada es muy pequeña.');
				}
			},

			cutImage(data) {
				// Variables
				const initX = data.x,
					initY = data.y,
					initWidth = data.width,
					initHeight = data.height,
					newWidth = this.setSize,
					zoom = initWidth / newWidth;

				// La declaro
				let imageTemp = new Image();
				// Cuando la imagen se carge se procederá al recorte
				imageTemp.onload = () => {
					/* Se crea un canvas para cargar el recorte de la libreria */
					let canvasVersion = document.createElement('canvas');
					canvasVersion.setAttribute('width', `${this.setSize}px`);
					canvasVersion.setAttribute('height', `${this.setSize}px`);

					/* Se dibuja en el canvas la imagen recortada */
					let ctx = canvasVersion.getContext('2d');
					ctx.drawImage(
						imageTemp,
						initX,
						initY,
						initWidth * zoom,
						initHeight * zoom,
						0,
						0,
						initWidth,
						initHeight
					);

					// Se transforma a base64 para almacenar
					this.imageBase64 = canvasVersion.toDataURL('image/jpeg');
				};

				// Proporciona la imagen cruda, sin editarla por ahora
				imageTemp.src = this.urlImage;
			},
		},
	};
</script>
