<template>
	<v-dialog
		v-model="dialog"
		fullscreen
		hide-overlay
		transition="dialog-bottom-transition"
	>
		<v-card>
			<v-toolbar dark color="deep-orange darken-1">
				<v-btn icon dark @click="close">
					<v-icon>mdi-close</v-icon>
				</v-btn>

				<v-toolbar-title>Perfil de usuario</v-toolbar-title>

				<v-spacer></v-spacer>

				<v-toolbar-items>
					<v-btn dark text @click="saveUserForm()"> Guardar </v-btn>
				</v-toolbar-items>
			</v-toolbar>
			<v-card-text>
				<v-row>
					<v-col cols="6">
						<form class="pt-6">
							<v-text-field
								:value="user.name"
								label="Nombre"
								type="text"
								disabled
							></v-text-field>

							<v-text-field
								:value="user.user ? user.user.email : ''"
								label="Email"
								type="email"
								disabled
							></v-text-field>

							<v-text-field
								v-model="telephone"
								:value="user.telephone"
								label="Teléfono"
								type="text"
								required
							></v-text-field>

							<v-text-field
								:value="
									user.association_area
										? user.association_area.name
										: ''
								"
								label="Área"
								type="text"
								disabled
							></v-text-field>

							<template
								v-if="
									user.association_area &&
									user.association_area.code == 'watchman'
								"
							>
								<h2>Regantes asignados</h2>
								<div>
									<v-chip
										v-for="(
											item, index
										) in irrigatorsToSend"
										:key="index"
										class="ma-2 primary"
										text-color="white"
									>
										<v-avatar left class="blue darken-4">
											{{ 1 + index }}
										</v-avatar>
										{{ item.name }} /
										{{ item.code }}
									</v-chip>
								</div>
							</template>

							<v-text-field
								v-model="password"
								label="Contraseña"
								type="password"
								disabled
							></v-text-field>
						</form>
					</v-col>

					<v-col cols="6">
						<UserProfilePicture
							v-if="dialog"
							@updatedPicture="e => (picture = e)"
						/>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
	import UserProfilePicture from '@/components/UserProfilePicture';

	import { mapActions, mapState } from 'vuex';

	export default {
		name: 'user-profile-update',

		props: {
			activateDialog: {
				type: Boolean,
			},
		},

		data() {
			return {
				dialog: false,
				telephone: null,
				password: null,
				picture: null,
				irrigatorsToSend: null,
			};
		},

		watch: {
			activateDialog: function (newValue) {
				this.dialog = newValue;
			},

			user: {
				handler(newvalue) {
					this.telephone = newvalue.telephone;
					this.irrigatorsToSend = newvalue.assigned_irrigators;
				},
				deep: true,
			},
		},

		components: {
			UserProfilePicture,
		},

		computed: {
			...mapState({
				user: state => state.userControl.user,
				channels: state => state.irrigatorControl.channels,
			}),

			toSend() {
				let user = {};

				user.id = this.user.id;
				user.user_id = this.user.user.id;
				user.telephone = this.telephone;
				user.password = this.password;

				if (this.picture) user.picture = this.picture;

				return user;
			},
		},

		methods: {
			...mapActions('userControl', ['saveUser']),

			saveUserForm() {
				this.saveUser(this.toSend).then(() => {
					this.$emit('close:profile');
				});
			},

			close() {
				this.$emit('close:profile');
			},
		},
	};
</script>
